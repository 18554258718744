import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'

import './reset.css'
import './index.css'

import menulogo from '../img/menulogo.svg'
import smallmenulogo from '../img/smallmenulogo.svg'

const Menu = () => (
  <div className="menu">
    <Link to="/acts/">ARTISTS</Link><br/>
    <Link to="/catalog/">RELEASES</Link><br/>
    <Link to="/videos/">VIDEOS</Link><br/>
    <Link to="/store/">STORE</Link><br/>
    <Link to="/about/">ABOUT</Link><br/>
  </div>
)

const SmallMenu = () => (
  <div className="menu">
    <Link to="/acts/">ARTISTS</Link>
    <Link to="/catalog/">RELEASES</Link>
    <Link to="/videos/">VIDEOS</Link>
    <Link to="/store/">STORE</Link>
    <Link to="/about/">ABOUT</Link>
  </div>
)

const GA = `
  var _gaq = _gaq || [];
  _gaq.push(['_setAccount', 'UA-18630678-1']);
  _gaq.push(['_trackPageview']);
  (function() {
  var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
  ga.src = ('https:' == document.location.protocol ? 'https://ssl' : 'http://www') + '.google-analytics.com/ga.js';
  var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
  })();
  `

const TemplateWrapper = ({ children, data }) => (
  <div className="c0">
    <Helmet
      title="ON AXIS"
      meta={[
        { name: 'description', content: '' },
        { name: 'keywords', content: 'on-axis record label stockholm hip hop sweden rap vesuvio kassler' },
      ]}
    >
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    </Helmet>
    <div className="c1">
      <div className="sidebar">
        <hr className="top1" />
        <hr className="top2" />
        <div className="logo">
          <Link to="/">
            <img src={menulogo} alt="On-Axis Logo" />
          </Link>
        </div>
        <Menu />
      </div>
      <div className="c3">
        <div className="smallheader">
          <div className="logo">
            <Link to="/">
              <img src={smallmenulogo} alt="On-Axis Logo" />
            </Link>
          </div>
          <SmallMenu />
        </div>
        <div className="c4">
          <hr className="top1" />
          <hr className="top2" />
          {children}
        </div>
        <div className="c5">
          <hr />
          <p>
            <a href="https://www.facebook.com/onaxism">Facebook</a>
            <span>|</span>
            <a href="https://instagram.com/onaxism">Instagram</a>
            <span>|</span>
            <a href="https://soundcloud.com/on-axis">Soundcloud</a>
            <span>|</span>
            <a href="https://open.spotify.com/search/results/label%3Aon-axis">Spotify</a>
            <span>|</span>
            <a href="http://twitter.com/onaxism">Twitter</a>
            <span>|</span>
            <a href="http://www.youtube.com/user/onaxism/videos">Youtube</a><br/>
            &copy; 1999-2022 On-Axis Recordings, All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
    <script type="text/javascript" dangerouslySetInnerHTML={{ __html: GA }} />
  </div>
)

export default TemplateWrapper
